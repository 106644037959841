<script lang="ts" setup>
import { computed, provide, ref, watch } from 'vue'
import { useElementBounding } from '@vueuse/core'
import type { ProductDescriptionItem } from '@/components/product-shared/DescriptionItems'
import ProductDescriptionListDashes from '@/components/product-shared/ProductDescriptionListDashes.vue'
import DescriptionContent from './DescriptionContent.vue'
import Avatar from './Avatar.vue'
import { getSectionStore } from '../stores'
import type { AssetKey } from '../DescriptionItems/TestReplay/list'
import type { GetImageResult } from 'astro'

const props = defineProps<{
  sectionIdBefore: string
  sectionIdCurrent: string
  lastSection?: boolean
  items: ProductDescriptionItem[]
  assets: Record<AssetKey, GetImageResult>
}>()

const { bottomAvatar: beforeBottomAvatar } = getSectionStore(props.sectionIdBefore)
const {
  bottomAvatar: currentBottomAvatar,
  running,
  topThreshold,
  sectionTop,
  topAvatar,
} = getSectionStore(props.sectionIdCurrent)

const $descriptionContent = ref<HTMLDivElement>()
const { bottom: bottomOfDescription } = useElementBounding($descriptionContent)

const localCurrentBottomAvatar = computed(() => {
  if (!bottomOfDescription.value) {
    return false
  }

  return bottomOfDescription.value < topThreshold.value + 24
})

// if we load the page in the middle, the app part is not executed
// for optimization reasons, so we need to set the avatar.
// see https://github.com/cypress-io/cypress.io/pull/636
watch(
  localCurrentBottomAvatar,
  (value) => {
    currentBottomAvatar.value = value
    if (value) {
      beforeBottomAvatar.value = true
    }
  },
  { immediate: true },
)

watch(running, (value) => {
  if (value) {
    beforeBottomAvatar.value = true
  }
})

provide('injection', {
  testReplayAssets: props.assets,
})
</script>

<template>
  <div ref="$descriptionContent" class="relative z-0 pb-[32px]">
    <ProductDescriptionListDashes
      class="absolute left-[4px] z-10 mt-[-60px] hidden h-[60px] sm:left-[36px] lg:left-[50%] lg:block"
      :active="beforeBottomAvatar"
    />
    <div
      class="absolute left-[2px] z-20 mt-[-60px] hidden h-[60px] w-[8px] bg-gradient-to-b from-white/100 to-white/0 sm:left-[36px] lg:left-[50%] lg:ml-[-1px] lg:block"
    />
    <Avatar :section-id="sectionIdCurrent" src="/images/avatar.png" />
    <DescriptionContent :items="items" :section-id="props.sectionIdCurrent" :active="beforeBottomAvatar" />
    <ProductDescriptionListDashes class="absolute left-[4px] hidden h-[32px] sm:left-[36px] lg:left-[50%] lg:block" />
  </div>
</template>
